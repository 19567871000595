import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { AiOutlineGlobal, AiOutlineCheck } from "react-icons/ai";
import { languages, currencies } from '../organisms/PricingScreen';

interface SelectorProps {
  language: { value: string; label: string };
  setLanguage: (language: { value: string; label: string }) => void;
  currency: { value: string; label: string };
  setCurrency: (currency: { value: string; label: string }) => void;
}

const SelectorContainer = styled.div`
  position: relative;
`;

const SelectorButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: #4a5568;
  font-size: 0.875rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 9999px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #F7F7F7;
  }

  svg {
    margin-right: 0.5rem;
	width: 1.2rem;
	height: 1.2rem;
  }
`;

const DropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.3rem;
  background-color: white;
  border-radius: 0.375rem;
  box-shadow: 
    0 -4px 6px rgba(0, 0, 0, 0.1),
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 -1px 1px rgba(0, 0, 0, 0.08),
    0 2px 3px rgba(0, 0, 0, 0.08);
  width: 16rem;
  z-index: 10;
`;

const DropdownSection = styled.div`
  padding: 1rem;

  &:not(:last-child) {
    border-bottom: 1px solid #e2e8f0;
  }
`;

const SectionTitle = styled.h3`
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  color: rgb(107, 114, 128);
  margin-bottom: 0.5rem;
`;

const OptionsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const OptionItem = styled.li<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: #F7F7F7;
  }

  ${props => props.isSelected && `
    font-weight: 600;
  `}
`;

export default function LanguageCurrencySelector({ language, setLanguage, currency, setCurrency }: SelectorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const selectorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleLanguageSelect = (lang: typeof languages[0]) => {
    setLanguage(lang);
  };

  const handleCurrencySelect = (curr: typeof currencies[0]) => {
    setCurrency(curr);
  };

  return (
    <SelectorContainer ref={selectorRef}>
      <SelectorButton onClick={toggleDropdown} aria-haspopup="true" aria-expanded={isOpen}>
        <AiOutlineGlobal aria-hidden="true" />
        <span>{language.label} - {currency.label}</span>
      </SelectorButton>

      {isOpen && (
        <DropdownContainer>
          <DropdownSection>
            <SectionTitle>Select language</SectionTitle>
            <OptionsList>
              {languages.map((lang) => (
                <OptionItem
                  key={lang.value}
                  isSelected={language.value === lang.value}
                  onClick={() => handleLanguageSelect(lang)}
                >
                  <span>{lang.label}</span>
                  {language.value === lang.value && <AiOutlineCheck aria-hidden="true" />}
                </OptionItem>
              ))}
            </OptionsList>
          </DropdownSection>
          <DropdownSection>
            <SectionTitle>Select currency</SectionTitle>
            <OptionsList>
              {currencies.map((curr) => (
                <OptionItem
                  key={curr.value}
                  isSelected={currency.value === curr.value}
                  onClick={() => handleCurrencySelect(curr)}
                >
                  <span>{curr.label}</span>
                  {currency.value === curr.value && <AiOutlineCheck aria-hidden="true" />}
                </OptionItem>
              ))}
            </OptionsList>
          </DropdownSection>
        </DropdownContainer>
      )}
    </SelectorContainer>
  );
}

