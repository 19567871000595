import { useState } from 'react';
import styled, { css } from 'styled-components';
import LanguageCurrencySelector from '../molecules/language-currency-selector';
import { useSearchParams } from 'react-router-dom';

export interface PricingTierFrequency {
	id: string;
	value: string;
	label: Record<string, string>;
	priceSuffix: Record<string, string>;
}

export interface PricingTier {
	name: Record<string, string>;
	id: string;
	href: string | Record<string, Record<string, string>>
	discountPrice: string | Record<string, string>;
	price: Record<string, string> | Record<string, Record<string, string>>;
	description: Record<string, string>;
	features: Record<string, string[]>;
	headline?: Record<string, string>;
	featured?: boolean;
	highlighted?: boolean;
	cta: Record<string, string>;
}

export const frequencies: PricingTierFrequency[] = [
	{ 
		id: '1', 
		value: '1', 
		label: {
			'en': 'Billed Monthly',
			'fr': 'Facturé mensuellement',
			'de': 'Monatlich abgerechnet',
		}, 
		priceSuffix: {
			'en': 'per zone /month',
			'fr': 'par zone /mois',
			'de': 'pro Zone /Monat',
		}
	},
	{ 
		id: '2', 
		value: '2', 
		label: {
			'en': 'Billed Yearly (Save 12%)',
			'fr': 'Facturé annuellement (Économisez 12%)',
			'de': 'Jährlich abgerechnet (12% sparen)',
		}, 
		priceSuffix: {
			'en': 'per zone /month',
			'fr': 'par zone /mois',
			'de': 'pro Zone /Monat',
		}
	}
];

export const currencies = [
  { value: 'usd', label: 'US Dollar ($)' },
  { value: 'eur', label: 'Euro (€)' },
  { value: 'gbp', label: 'British Pound (£)' },
]

export const languages = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'French' },
  { value: 'de', label: 'German' },
];

export const tiers: PricingTier[] = [
	{
		name: {
			'en': 'Essential',
			'fr': 'Essentiel',
			'de': 'Essentiell',
		},
		id: '0',
		href: {
			'1': {
				'usd': 'https://account.soundsuit.fm/subscribe?plan_id=essential-usd-zone-monthly',
				'eur': 'https://account.soundsuit.fm/subscribe?plan_id=essential-eur-zone-monthly',
				'gbp': 'https://account.soundsuit.fm/subscribe?plan_id=essential-gbp-zone-monthly'
			},
			'2': {
				'usd': 'https://account.soundsuit.fm/subscribe?plan_id=essential1-usd-zone-yearly',
				'eur': 'https://account.soundsuit.fm/subscribe?plan_id=essential1-eur-zone-yearly',
				'gbp': 'https://account.soundsuit.fm/subscribe?plan_id=essential1-gbp-zone-yearly'
			}
		},
		price: {
			'usd': { '1': '$37', '2': '$32' },
			'eur': { '1': '34€', '2': '29€' },
			'gbp': { '1': '£29', '2': '£25' },
		},
		discountPrice: { '1': '', '2': '' },
		description: {
			'en': 'For individual businesses, with one or more zones.',
			'fr': 'Pour les entreprises individuelles, avec une ou plusieurs zones.',
			'de': 'Für einzelne Unternehmen mit einer oder mehreren Zonen.',
		},
		features: {
			'en': ['Legal for business use', 'Works with all sound systems', 'Native in Sonos & Loxone', 'Central control of zones', 'Create own (radio) stations', 'Schedule stations in calendar', 'Explicit content strickly filtered', 'Fresh new songs weekly'],
			'fr': ['Légal pour un usage commercial', 'Fonctionne avec toutes sonorisations', 'Intégré nativement sur Sonos & Loxone', 'Contrôle centralisé (via Dashboard)', 'Stations (de radio) personalisées', 'Calendrier de musique', 'Les contenus explicites sont rigoureusement filtrés', 'Nouveaux titres chaque semaine'],
			'de': ['Legal für die geschäftliche Nutzung', 'Funktioniert mit allen Soundsystemen', 'Native in Sonos Loxone', 'Zentral gesteuert', 'Eigene Stations erstellen', 'Stations in Kalender einplanen', 'Explizite Inhalte werden streng gefiltert', 'Neue Songs wöchentlich'],
		},
		featured: false,
		highlighted: false,
		cta: {
			'fr': "S'abonner",
			'en': "Buy now",
			'de': 'Jetzt kaufen',
		}
	},
	{
		name: {
			'en': 'Professional',
			'fr': 'Professionnel',
			'de': 'Professionell',
		},
		id: '1',
		href: {
			'1': {
				'usd': 'https://account.soundsuit.fm/subscribe?plan_id=pro1-usd-zone-monthly',
				'eur': 'https://account.soundsuit.fm/subscribe?plan_id=pro1-eur-zone-monthly',
				'gbp': 'https://account.soundsuit.fm/subscribe?plan_id=pro1-gbp-zone-monthly'
			},
			'2': {
				'usd': 'https://account.soundsuit.fm/subscribe?plan_id=pro1-usd-zone-yearly',
				'eur': 'https://account.soundsuit.fm/subscribe?plan_id=pro1-eur-zone-yearly',
				'gbp': 'https://account.soundsuit.fm/subscribe?plan_id=pro1-gbp-zone-yearly'
			}
		},
		price: {
			'usd': { '1': '$47', '2': '$42' },
			'eur': { '1': '44€', '2': '39€' },
			'gbp': { '1': '£38', '2': '£33' },
		},
		discountPrice: { '1': '', '2': '' },
		description: {
			'en': 'For companies with multiple locations and music zones.',
			'fr': 'Pour les entreprises ayant plusieurs sites et plusieurs zones musicales.',
			'de': 'Für Unternehmen mit mehreren Standorten und Musikzonen.'
		},
		headline: {
			'en': 'Everything in Essential plan',
			'fr': 'Tout dans forfait Essentiel',
			'de': 'Alles in Essentiell Plan'
		},
		features: {
			'en': ['Central control of locations', 'Create own playlists', 'Schedule playlists in calendar', 'Priority support'],
			'fr': ['Contrôle centralisé des sites', 'Playlists', 'Calendrier mixant stations et playlists', 'Accès prioritaire au service client'],
			'de': ['Zentral gesteuert', 'Eigene Playlists erstellen', 'Playlists in Kalender einplanen', 'Prioritäre Unterstützung'],
		},
		featured: false,
		highlighted: true,
		cta: {
			'fr': "S'abonner",
			'en': "Buy now",
			'de': 'Jetzt kaufen',
		}
	},
	{
		name: {
			'en': 'Enterprise',
			'fr': 'Grands groupes',
			'de': 'Unternehmen',
		},
		id: '2',
		href: 'https://soundsuit.fm/contact/',
		price: {
			'en': 'Custom',
			'fr': 'Sur devis',
			'de': 'Nach Anfrage',
		},
		discountPrice: { '1': '', '2': '' },
		description: {
			'en': 'For brands with multiple regions, locations and music zones.',
			'fr': 'Pour les marques ayant plusieurs régions, sites et zones musicales.',
			'de': 'Für Marken mit mehreren Regionen, Orten und Musikzonen.'
		},
		headline: {
			'en': 'Everything in Professional plan',
			'fr': 'Tout dans le forfait professionnel',
			'de': 'Alles in Professionell Plan'
		},
		features: {
			'en': ['Brand Music Identity by experts', 'Import of existing playlists', 'Dedicated account manager'],
			'fr': ['Création de votre identité musicale de marque par des experts', 'Importation de playlists existantes', 'Account-Manager dédié'],
			'de': ['Musik-Branding von Experten', 'Import von bestehenden Playlists', 'Persönlicher Account-Manager'],
		},
		featured: false,
		highlighted: false,
		cta: {
			'fr': "Nous contacter",
			'en': "Contact us",
			'de': 'Anfrage stellen',
		}
	},
];

const StyledSvg = styled.svg`
  width: 2em;
  height: 2em;
  flex-shrink: 0;
  display: inline-block;
`;


const CheckIcon: React.FC = () => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="#4760FF"
    >
      <path
        fillRule="evenodd"
        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
        clipRule="evenodd"
      />
    </StyledSvg>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background-color: white;
`

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 0 1.5rem;

  @media (min-width: 1024px) {
    padding: 0 2rem;
  }
`

const TopControls = styled.div`
  margin-top: 1rem;
  width: 100%;
  max-width: 1114px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`

const FrequencySelector = styled.div`
  display: grid;
  grid-auto-flow: column;
  border-radius: 100px;
  padding: 0.3rem;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: white;
  border: 1px solid #e5e7eb;
  width: fit-content;

  @media (min-width: 768px) {
    gap: 0.3rem;
  }
`;

const FrequencyOption = styled.label<{ isSelected: boolean }>`
  cursor: pointer;
  border-radius: 100px;
  padding: 0.5rem 0.75rem;
  transition: all 0.2s;
  background-color: ${props => props.isSelected ? '#000' : 'transparent'};
  color: ${props => props.isSelected ? 'white' : '#6b7280'};

  &:hover {
    background-color: ${props => props.isSelected ? '#000' : 'rgba(0, 0, 0, 0.1)'};
  }
`;

const TiersContainer = styled.div<{ tiersCount: number }>`
  isolation: isolate;
  margin-top: 1rem;
  display: grid;
  max-width: 36rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;

  @media (min-width: 1024px) {
    margin-left: 0;
    margin-right: 0;
    max-width: none;
    grid-template-columns: ${props => 
      props.tiersCount === 2 ? 'repeat(2, minmax(0, 1fr))' : 
      props.tiersCount === 3 ? 'repeat(3, minmax(0, 1fr))' : 
      'repeat(1, minmax(0, 1fr))'};
  }
`;
const TierCard = styled.div<{ featured: boolean; highlighted: boolean }>`
  max-width: 350px;
  border-radius: 1.5rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  border: 1px solid rgba(209, 213, 219, 0.7);
  justify-content: space-between;

  ${props =>
    props.highlighted &&
    css`
      background: rgba(50, 50, 50, 0.04);
      backdrop-filter: blur(6px);
    `}
`;


const TierName = styled.h3<{ featured: boolean }>`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
  color: ${props => props.featured ? 'white' : 'black'};
`;

const TierDescription = styled.p<{ featured: boolean }>`
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin: 0;
  color: ${props => props.featured ? '#d1d5db' : '#4b5563'};
`;

const PriceContainer = styled.p`
  display: flex;
  align-items: baseline;
  gap: 0.25rem;
  margin: 0;
`;

const Price = styled.span<{ featured: boolean; discounted: boolean }>`
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1.2;
  color: ${props => props.featured ? 'white' : 'black'};
  text-decoration: ${props => props.discounted ? 'line-through' : 'none'};
`;

const PriceSuffix = styled.span<{ featured: boolean }>`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: ${props => props.featured ? '#d1d5db' : '#4b5563'};
`;

const FeatureList = styled.ul<{ featured: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding: 0;
  color: ${props => (props.featured ? '#d1d5db' : '#374151')};
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
`;

const CTAButton = styled.button<{ 
  featured?: boolean; 
  highlighted?: boolean; 
  variant?: 'primary' | 'secondary' | 'tertiary' 
}>`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  height: 3rem;
  border-radius: 0.75rem;
  border: 1px solid transparent;
  padding: 0 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  gap: 0.5rem;
  transition: 
    background-color 0.3s ease,
    transform 0.2s ease,
    box-shadow 0.2s ease,
    border-color 0.3s ease;
  
  box-shadow: 
    0 1px 3px rgba(0, 0, 0, 0.1),
    0 1px 2px rgba(0, 0, 0, 0.06);
  
  ${props => {
    if (props.featured) {
      return css`
        background-color: #f3f4f6;
        color: #4b5563;
        border-color: #e5e7eb;
        filter: grayscale(70%);
        
        &:hover {
          filter: grayscale(40%);
          background-color: #e5e7eb;
          transform: scale(1.02);
          box-shadow: 
            0 4px 6px rgba(0, 0, 0, 0.1),
            0 2px 4px rgba(0, 0, 0, 0.06);
        }
      `;
    } else if (props.highlighted) {
      return css`
        background-color: #4760FF;
        color: white;
        border-color: #4760FF;
        box-shadow: 
          0 4px 6px rgba(99, 160, 246, 0.3),
          0 1px 3px rgba(99, 160, 246, 0.3);
        
        &:hover {
          background-color: #4760FF;
          transform: translateY(-1px);
          box-shadow: 
            0 6px 8px rgba(99, 160, 246, 0.3),
            0 2px 4px rgba(99, 160, 246, 0.3);
        }
      `;
    } else {
      return css`
        background-color: white;
        color: #374151;
        border-color: #d1d5db;
        
        &:hover {
          background-color: #f9fafb;
          border-color: #9ca3af;
          box-shadow: 
            0 2px 4px rgba(0, 0, 0, 0.08),
            0 1px 2px rgba(0, 0, 0, 0.04);
        }
      `;
    }
  }}
  
  &:focus-visible {
    outline: none;
    border-color: #4760FF;
    box-shadow: #4760FF
      0 0 0 3px 
      0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
    background-color: #f9fafb;
    color: #9ca3af;
    box-shadow: none;
    cursor: not-allowed;
  }
  
  &:active {
    transform: scale(0.98);
  }
`;

export default function PricingScreen() {
  const [frequency, setFrequency] = useState(frequencies[1]);
  const [currency, setCurrency] = useState(currencies[1]);
  const [language, setLanguage] = useState(languages[0]);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  return (
      <Container>
        <InnerContainer>
          {frequencies.length > 1 && (
            <TopControls>
              <LanguageCurrencySelector 
                  language={language} 
                  setLanguage={setLanguage} 
                  currency={currency} 
                  setCurrency={setCurrency} 
                />
                <FrequencySelector
                  role="radiogroup"
                  style={{
                    gridTemplateColumns: `repeat(${frequencies.length}, minmax(0, 1fr))`,
                  }}
                >
                  {frequencies.map((option) => (
                    <FrequencyOption
                      key={option.value}
                      htmlFor={option.value}
                      isSelected={frequency.value === option.value}
                    >
                      {option.label[language.value]}
                      <button
                        value={option.value}
                        id={option.value}
                        style={{ display: 'none' }}
                        role="radio"
                        aria-checked={frequency.value === option.value}
                        onClick={() => setFrequency(frequencies.find(f => f.value === option.value))}
                      >
                        {option.label[language.value]}
                      </button>
                    </FrequencyOption>
                  ))}
                </FrequencySelector>
                
            </TopControls>

          )}

          <TiersContainer tiersCount={tiers.length}>
            {tiers.map((tier) => (
              <TierCard key={tier.id} featured={tier.featured} highlighted={tier.highlighted}>
                <TierName id={tier.id} featured={tier.featured}>
                  {tier.name[language.value]}
                </TierName>
                <TierDescription featured={tier.featured}>
                  {tier.description[language.value]}
                </TierDescription>
                <PriceContainer>
                  <Price
                    featured={tier.featured}
                    discounted={!!tier.discountPrice && !!tier.discountPrice[frequency.value]}
                  >
                    {typeof tier.price[language.value] === 'string'
                      ? tier.price[language.value]
                      : tier.price[currency.value][frequency.value]}
                  </Price>
                  {tier.discountPrice && (
                    <Price featured={tier.featured} discounted={false}>
                      {typeof tier.discountPrice === 'string'
                        ? tier.discountPrice
                        : tier.discountPrice[frequency.value]}
                    </Price>
                  )}
                  {typeof tier.price !== 'string' && !tier.price[language.value] && (
                    <PriceSuffix featured={tier.featured}>
                      {frequency.priceSuffix[language.value]}
                    </PriceSuffix>
                  )}
                </PriceContainer>
                {tier.headline && (
                  <div style={{ display: 'flex', gap: '0.5rem', borderTop: '1px solid #4760FF', borderBottom: '1px solid #4760FF', padding: '1rem 0', alignItems: 'center' }}>
                    <CheckIcon />
                    {tier.headline[language.value]}
                  </div>
                )}
                <FeatureList featured={tier.featured}>
                  {tier.features[language.value].map((feature) => (
                    <FeatureItem key={feature}>
                      <CheckIcon />
                      {feature}
                    </FeatureItem>
                  ))}
                </FeatureList>
                <a
                  href={typeof tier.href === 'string' ? tier.href : tier.href[frequency.value][currency.value] + (token ? `&token=${token}` : '')}
                  aria-describedby={tier.id}
                  style={{ display: 'flex', marginTop: 'auto' }}
                >
                  <CTAButton
                    featured={tier.featured}
                    highlighted={tier.highlighted}
                  >
                    {tier.cta[language.value]}
                  </CTAButton>
                </a>
              </TierCard>
            ))}
          </TiersContainer>
        </InnerContainer>
      </Container>
  );
}