import React, { useState } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import logo from "../../assets/soundSuitIcon/Soundsuit_logo.svg";
import { newPassword as newPasswordService } from "../../services/SoundSuitService";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

const ResetPassword = () => {
  const { token, email } = useParams<{ token: string, email: string }>();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleSave = async () => {
    setError(null);

    if (newPassword.length < 6) {
      setError("Password must be at least 6 characters long.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      const resultReset = await newPasswordService({ 
        password: newPassword, 
        password_confirmation: confirmPassword, 
        token 
      });

      if (resultReset === "") {
        navigate("/success-password", { state: { email } });
      } else {
        setError('Account not found.')
      }
    } catch (err) {
      setError("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <Container>
      <LogoWrapper>
        <Logo src={logo} alt="Soundsuit Logo" />
      </LogoWrapper>
      <Title>Reset your password</Title>
      <Description>
        Enter the new password you want to use with your Soundsuit account{" "}
        <UserEmail>{email}</UserEmail>.
      </Description>
      <Form>
        <TitleForm align="center">New Password</TitleForm>
        <InputWrapper>
          <Input
            type={showPassword1 ? "text" : "password"}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="New Password"
          />
          <IconButton
            type="button"
            onClick={() => setShowPassword1(!showPassword1)}
            aria-label={showPassword1 ? "Hide password" : "Show password"}
          >
            {showPassword1 ? <EyeInvisibleOutlined style={{ fontSize: '18px'}} /> : <EyeOutlined style={{ fontSize: '18px'}} />}
          </IconButton>
        </InputWrapper>
        <TitleForm align="center">Confirm Password</TitleForm>
        <InputWrapper>
          <Input
            type={showPassword2 ? "text" : "password"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Re-Enter New Password"
          />
          <IconButton
            type="button"
            onClick={() => setShowPassword2(!showPassword2)}
            aria-label={showPassword2 ? "Hide password" : "Show password"}
          >
            {showPassword2 ? <EyeInvisibleOutlined style={{ fontSize: '18px'}} /> : <EyeOutlined style={{ fontSize: '18px'}} />}
          </IconButton>
        </InputWrapper>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <SaveButton 
          onClick={handleSave} 
          disabled={newPassword.length === 0 || confirmPassword.length === 0}
        >
          Save
        </SaveButton>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 30px;
`;

const LogoWrapper = styled.div`
  margin-bottom: 10px;
`;

const Logo = styled.img`
  width: 60px;
  height: auto;
  border-radius: 50%;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 50px;
`;

const Description = styled.p`
  font-size: 14px;
  text-align: center;
  color: #555555;
  margin-bottom: 30px;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #808EAF44;
  border-radius: 10px;
  font-size: 14px;
  padding-right: 40px;
`;

const IconButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  color: #808EAF;

  &:hover {
    color: #666;
  }
`;

const UserEmail = styled.span`
  color: #007aff;
`;

const Form = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
`;

const TitleForm = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 5px;
  margin-left: 4px;
`;

const SaveButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: ${({ disabled }) => (disabled ? '#d3d3d3' : '#FF6259')};
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  margin-top: 10px;
  margin-bottom: 25px;

  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#d3d3d3' : '#EE6259')};
  }
`;

const ErrorMessage = styled.div`
  color: #ff0000;
  font-size: 12px;
  margin-bottom: 0px;
`;

export default ResetPassword;
